<template>
    <div>
        <el-form :inline="true">
            <el-form-item label="状态" label-width="100">
                <el-select v-model="invoiceStatus" clearable placeholder="状态">
                    <el-option label="申请中" value="1"></el-option>
                    <el-option label="已开票" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="current = 1, getCustomerservice()">搜索</el-button>
                <el-button type="primary" @click="exportSurfList()">待开票导出</el-button>
            </el-form-item>
        </el-form>
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe>
            <el-table-column prop="invoiceId" align="center" label="ID" width="100">
            </el-table-column>
            <el-table-column prop="invoiceStatus" align="center" label="申请状态" width="100">
                <template slot-scope="{row}">
                    <span v-if="row.invoiceStatus == 1">申请中</span>
                    <span v-if="row.invoiceStatus == 2">已开票</span>
                </template>
            </el-table-column>
            <el-table-column prop="invoiceType" align="center" label="抬头资质" width="100">
                <template slot-scope="{row}">
                    <span v-if="row.invoiceType == 1">普票抬头</span>
                    <span v-if="row.invoiceType == 2">专票抬头</span>
                </template>
            </el-table-column>
            <el-table-column prop="headingType" align="center" label="抬头类型" width="100">
                <template slot-scope="{row}">
                    <span v-if="row.headingType == 1">企业单位</span>
                    <span v-if="row.headingType == 2">个人/非企业单位</span>
                </template>
            </el-table-column>
            <el-table-column prop="invoiceHeader" align="center" label="发票抬头" width="200">
            </el-table-column>
            <el-table-column prop="dutyParagraph" align="center" label="纳税人识别号" width="200">
            </el-table-column>
            <el-table-column prop="bankAccount" align="center" label="银行账号" width="200">
            </el-table-column>
            <el-table-column prop="depositBank" align="center" label="开户银行" width="160">
            </el-table-column>
            <el-table-column prop="companyRegisteredAddress" align="center" label="注册地址" width="200">
            </el-table-column>
            <el-table-column prop="registrationPhone" align="center" label="注册电话" width="120">
            </el-table-column>
            <el-table-column prop="enterpriseMailbox" align="center" label="企业邮箱" width="200">
            </el-table-column>

            <el-table-column prop="goods" align="center" label="货物" width="240">
            </el-table-column>
            <el-table-column prop="invoiceTotal" align="center" label="开票金额" width="100">
            </el-table-column>
            <el-table-column prop="recipientInformation" align="center" label="收件人姓名" width="120">
            </el-table-column>
            <el-table-column prop="phone" align="center" label="收件人手机号" width="120">
            </el-table-column>
            <el-table-column prop="recipientAddress" align="center" label="收件人地址" width="220">
            </el-table-column>
            <el-table-column prop="emailAddress" align="center" label="收件人邮箱" width="200">
            </el-table-column>
            <el-table-column prop="startingPoint" align="center" label="运输起止点" width="300">
                <template slot-scope="{row}">
                    <div>
                        起始点： {{ row.startingPoint }}
                    </div>
                    <div>
                        终止点： {{ row.endPoint }}
                    </div>
                </template>
            </el-table-column>

            <!-- <el-table-column prop="emailAddress" align="center" label="运输司机"  width="200">
            </el-table-column> -->
            <el-table-column prop="icon" align="center" width="200" label="操作" fixed="right">
                <template slot-scope="scope">
                    <template v-if="scope.row.invoiceStatus == 1">
                        <el-popconfirm title="确定已开票？" @confirm="updateInvoice(scope.row.invoiceId)">
                            <el-button type="success" plain slot="reference">开票</el-button>
                        </el-popconfirm>
                    </template>
                    <el-button type="primary" plain @click="detail(scope.row.invoiceId)">详情</el-button>
                   
                    <!-- <el-button type="primary" plain @click="saveHandle(scope.row)">审核开票</el-button> -->
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20, 50, 100]" :current-page="current"
            :page-size="size" :total="total">
        </el-pagination>
        <el-dialog title="开票" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
            <el-form :model="editForm" ref="editForm">
                <el-form-item label="开票审核状态" label-width="150px">
                    <el-radio-group v-model="editForm.invoiceStatus">
                        <el-radio :label="1">已开票</el-radio>
                        <el-radio :label="2">未通过</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="未通过原因" label-width="150px" v-if="editForm.invoiceStatus == 2">
                    <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="editForm.textarea" />
                </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="invoiceTrue">确 定</el-button>
            </span>
        </el-dialog>
        <getInvoiceDetail ref="saveInsuranceTypeDetail" v-if="saveInsuranceTypevisible"></getInvoiceDetail>
    </div>
</template>

<script>
import getInvoiceDetail from "./getInvoiceDetail";
import ty from "@/utils/ty";
export default {
    components: {
        getInvoiceDetail
    },
    data() {
        return {
            editForm: {},
            dialogVisible: false,
            tableData: [],
            saveInsuranceTypevisible: false,
            invoiceStatus: null,
            size: 10,
            current: 1,
            total: 0
        }
    },
    created() {
        this.getCustomerservice();
    },
    methods: {
        exportSurfList() {
            window.open( ty.apiurl+ '/admin/invoice/exportSurfList')
        },
        handleSizeChange(val) {
            this.size = val
            this.current = 1
            this.getCustomerservice()
        },
        handleCurrentChange(val) {
            this.current = val
            this.getCustomerservice()
        },
        getCustomerservice() {
            this.$axios.post("/admin/invoice/getInvoiceList", { pageNo: this.current, pageSize: this.size, invoiceStatus: this.invoiceStatus }).then(res => {
                this.tableData = res.data.records
                this.total = res.data.total
            })
        },
        updateInvoice(invoiceId) {
            this.$axios.post("/admin/invoice/updateInvoice", { invoiceId: invoiceId }).then(res => {
                this.getCustomerservice()
            })
        },
        handleClose() {
            this.editForm = {}
            this.dialogVisible = false
        },
        saveHandle(row) {
            this.editForm = JSON.parse(JSON.stringify(row))
            this.dialogVisible = true
        },

        invoiceTrue() {
            var that = this
            if (that.editForm.invoiceStatus == 2) {
                that.$message('请输入未通过原因');
                return false;
            }
            that.$axios.post("/admin/insurancetype/getInsuranceTypeList", that.editForm).then(res => {
                that.$message({
                    showClose: true,
                    message: '恭喜你，操作成功',
                    type: 'success',
                    onClose: () => {
                        that.current = 1
                        that.getCustomerservice()
                    }
                });
            })
        },
        detail(invoiceId) {
            this.saveInsuranceTypevisible = true;
            this.$nextTick(() => {
                this.$refs.saveInsuranceTypeDetail.init(invoiceId);
            })
        },
    }
}
</script>

<style scoped>
.el-button {
    padding: 0 10px;
    height: 35px;
    line-height: 35px;
    font-size: 15px;
    margin-right: 10px;
}
</style>

